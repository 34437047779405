import React from 'react';
import PropTypes from 'prop-types';
import getConfig from 'next/config';

import Standard from '@pages/standard';
import Error from '@pages/error';

import { withApollo } from '../graphql/apollo';
import { withTranslation, i18n } from '../i18n';

import standardQuery from '../graphql/queries/standard.graphql';

const {
  publicRuntimeConfig: { ACTIVE_SITE_HANDLE }
} = getConfig();

const StandardPage = ({ data, site }) =>
  data && data.entry ? (
    <Standard site={site} data={data} />
  ) : (
    <Error statusCode={404} site={site} />
  );

StandardPage.getInitialProps = async ctx => {
  const {
    req,
    query: { uri },
    apolloClient
  } = ctx;

  const { language } = req || i18n;
  const site = `${ACTIVE_SITE_HANDLE}${language.toUpperCase()}`;

  const { data, error } = await apolloClient.query({
    query: standardQuery,
    variables: { site, uri }
  });

  if ((data && !data.entry) || error) {
    if (ctx.res) {
      ctx.res.statusCode = 404;
    }
  }

  return {
    namespacesRequired: ['common'],
    site,
    data
  };
};

StandardPage.propTypes = {
  site: PropTypes.string.isRequired,
  data: PropTypes.shape().isRequired
};

StandardPage.defaultProps = {};

export default withApollo(withTranslation('common')(StandardPage));
